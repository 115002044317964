<template lang="pug">
div.eat-top-bar.d-flex.flex-column(:class="{ 'border-bottom': hasTopBarBorderBottom }")
  div.eat-top-bar__content.ps-2.pe-4.d-flex.align-center.flex-grow-1(:class="backgroundClass === 'bg-primary' ? 'bg-primary rounded-t-xxl' : backgroundClass")
    EatIconBtn(:icon="hasCloseButton ? '$close' : '$back'" v-if="showBackButton" @click="goBack($router, $route);")
    div.text-h6.pl-2.me-auto(v-if="!hideTitle") {{ appTitle }}
    LanguageDropDownMenu(v-if="hasLanguageSelector")
    div#topBarAction
    EatIconBtn(icon="$more" :size="18" @click="showMoreDialog = true" v-if="showMoreButton")
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useRoute } from "vue-router";
import { useAppTitle } from "@/store/appTitle";
import { goBack } from "@/store/routerHistory";
import { useShowMoreDialog } from "@/store/layout/moreDialog";
import LanguageDropDownMenu from "@/components/LanguageDropDownMenu.vue";

const appTitle = useAppTitle();
const route = useRoute();

const showMoreDialog = useShowMoreDialog();

const hasCloseButton = computed((): boolean => {
  return !!route.meta.hasCloseButton;
});

const hasLanguageSelector = computed((): boolean => {
  return route.name === "auth.login" || route.name === "auth.register";
});

const backgroundClass = computed(() => {
  return route.meta.backgroundClass || 'bg-white';
});

const showBackButton = computed(() => {
  return route.meta.showBackButton || route.meta.hasCloseButton;
});
const showMoreButton = computed(() => {
  return route.meta.showMoreButton;
});

const hideTitle = computed(() => {
  return route.meta.hideTitle;
})

const hasTopBarBorderBottom = computed(() => {
  return !!route.meta.topBarBorderBottom;
});
</script>

<style lang="scss" scoped>
.eat-top-bar {
  position: relative;
  z-index: 6;
  min-height: 48px;
}
</style>
