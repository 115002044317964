import type { Address } from "@/models/Address";
import { type Ref, ref } from "vue";

const newAddress: Ref<Address> = ref({
  address: "",
  city: "",
  streetNumber: "",
  zipCode: "",
  nation: "IT"
});

export function useNewAddress() {
  const clearNewAddress = () => {
    newAddress.value = {
      address: "",
      city: "",
      streetNumber: "",
      zipCode: "",
      nation: "IT"
    };
  };

  return {
    newAddress,
    clearNewAddress
  };
}

export function useGeolocation() {
  const geolocalize = async (timeout: number) => {
    return new Promise<{ lat: number; lng: number }>((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (position: GeolocationPosition) => {
          return resolve({ lat: position.coords.latitude, lng: position.coords.longitude });
        },
        error => reject(error),
        {
          timeout,
          enableHighAccuracy: true
        }
      );
    });
  };

  return {
    geolocalize
  };
}
